.page
{
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.linkStyle
{
    cursor: pointer;
    color: #1be2e2;
    margin: 2px;
    padding: 1px 6px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.linkStyle:hover
{
    color: #0da8a8;
    background-color: #fff;
    border-radius: 5px;
}

.active
{
    color: #0da8a8;
    background-color: #fff;
    border-radius: 5px;
}

.empty{
    color: #0da8a8;
    text-align: center;
    margin-top: 3px;
    font-weight: 900;
}