.user_form {
  border: 1px solid #1c6464;
  border-radius: 5px;
  padding: 18px 18px;
  width: 70%;
  background-color: #fff;
  box-shadow: 1px 1px 4px #ff7f50 inset;
  margin: 20px auto;
}

.user_controls {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.user_input {
  all: unset;
  border-style: none;
  width: 80%;
  max-height: 20%;

  color: rgb(2, 36, 36);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 20px;
  border: 1px solid rgb(4, 68, 68);
  border-radius: 5px;
}

.user_input:focus {
  border-style: none;
  box-shadow: none;
  border: 2px solid rgb(4, 68, 68);
}

.user_button {
  all: unset;
  background-color: rgb(4, 68, 68);
  color: #fff;
  font-size: 25px;
  width: 30%;
  border-style: none;
  outline-style: none;
  border-radius: 10px;
  text-align: center;
  margin: 3px 3px;
}

.user_button:hover {
  color: rgb(4, 68, 68);
  background-color: #fff;
  border: 1px solid rgb(4, 68, 68);
}

.user_label {
  color: rgb(28, 100, 100);
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 15px;
}

.user_heading {
  color: rgb(28, 100, 100);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.user_input_textarea {
  all: unset;
  border: 1px solid rgb(4, 68, 68);
  color: rgb(2, 36, 36);
  border-radius: 10px;
  color: rgb(2, 36, 36);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 20px;
}

.user_input_textarea:focus {
  border-style: none;
  box-shadow: none;
  border: 2px solid rgb(4, 68, 68);
}

.table_heading {
  color: rgb(28, 100, 100);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 18px;
  font-weight: 800;
  margin: 9px 9px;
}

.table_data {
  color: rgb(64, 175, 175);
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 14px;
  margin: 9px 9px;
}

.table_delete_button {
  all: unset;
  text-decoration: underline;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: #5a1e08;
}

.table_delete_button:hover {
  font-weight: 800;
}

.table_background {
  background-color: #fff;
  border: #a8a8a8 1px solid;
  border-radius: 8px;
  box-shadow: 1px 1px 4px #a8a8a8 inset;
  margin: 20px auto;
}

.user_img {
  margin: 3px 3px;
  border: #bd3f12 1px solid;
  border-radius: 3px;
}

.user_link_button {
  all: unset;
  text-decoration: underline;
  color: #bd3f12;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 13px;
}
.user_link_button:hover {
  font-weight: 800;
}

.user_file_div {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.user_file_div .user_inputfile {
  left: 0;
  top: 0;
  opacity: 0;
  position: absolute;
  font-size: 90px;
}

.user_file_button {
  background-color: #fff;
  color: #ff7f50;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 15px;
  border: #ff7f50 1px solid;
  border-radius: 3px;
  margin: 2px 1px;
  cursor: pointer;
}

.user_file_button:hover {
  color: #fff;
  background-color: #ff7f50;
}

.admin-main {
  margin-top: 5rem;
  margin-bottom: 40px;
  position: relative;
  height: 100%;
}

.home_admin
{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome_admin
{ 
  color: #ff7f50;
  text-align: center;
  font-size: 2rem;
}

.action_link {
  text-decoration: none;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 1rem;
  color: #1c6464;
}

.action_link:hover {
  color: #1c6464;
  border: 1px solid #1c6464;
  padding: 0.5rem 0.8rem;
  border-radius: 5rem;
}

.table_data_span
{
  max-width: 50%;
  text-align: left;
}

.icon_data{
  cursor: pointer;
  margin: 3px;
  text-align: center;
  font-size: 1rem;
}

@media (max-width: 850px) {
  .user_button {
    width: 50%;
  }

  .admin-main {
    margin-top: 13rem;
  }
}
