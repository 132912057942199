.App {
  text-align: center;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  align-items: center;
}

.space_around {
  margin: 2px 8px;
}

.common_header {
  color: #bd3f12;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-weight: 600;
  font-size: 16px;
}

.common_arrow {
  font-weight: bolder;
  font-size: 40px;
  color: rgb(13, 168, 168);
  cursor: pointer;
}

.common_error {
  color: #bd3f12;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 14px;
}

.spinner_suspense_container
{
  display: flex;
  justify-content: center;
  justify-items: center;
  margin: 50% auto;
  font-size: 4rem;
}