.main_checkout {
    display: flex;
    margin: 15px 5px;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.payment_checkout {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: rgb(86, 88, 88);
    padding: 2px 3px;
    width:50%
}
.payment_checkout_controls {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 5px 2px;
}

.summary_checkout {
    background-color: #cae2e2;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    border-radius: 10px;
    padding: 5px;
    color: rgb(57, 68, 68);
    width:40%
}

.checkout_button {
    all: unset;
    background-color: rgb(4, 68, 68);
    color: #fff;
    font-size: 24px;
    width: 50%;
    border-style: none;
    outline-style: none;
    border-radius: 10px;
    margin: 3px auto;
    text-align: center;
  }
  
  .checkout_button:hover {
    color: rgb(4, 68, 68);
    background-color: #fff;
    border: 1px solid rgb(4, 68, 68);
  }
  
  .checkout_input_textarea {
    all: unset;
    border: 1px solid rgb(4, 68, 68);
    color: rgb(2, 36, 36);
    border-radius: 10px;
    color: rgb(2, 36, 36);
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    font-size: 20px;
    width:70%
  }
  
  .checkout_input_textarea:focus {
    border-style: none;
    box-shadow: none;
    border: 2px solid rgb(4, 68, 68);
  }

  .checkout_input {
    all: unset;
    border-style: none;
    width: 70%;
    max-height: 20%;
  
    color: rgb(2, 36, 36);
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    font-size: 20px;
    border: 1px solid rgb(4, 68, 68);
    border-radius: 5px;
  }
  
  .checkout_input:focus {
    border-style: none;
    box-shadow: none;
    border: 2px solid rgb(4, 68, 68);
  }

  .checkout_label {
    color: #0b9c60;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    font-size: 1rem;
    font-weight: 900;
  }

  .checkout_input_expiry {
    all: unset;
    border-style: none;
    width: 20%;
    max-height: 20%;
  
    color: rgb(2, 36, 36);
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    font-size: 20px;
    
  }

  .checkout_input_card {
    all: unset;
    border-style: none;
    width: 20%;
    max-height: 20%;
    color: rgb(2, 36, 36);
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    font-size: 20px;
    
    
  }

  .checkout_expiry
  {
    display: flex;
    flex-direction: row;
    max-width: 70%;
    border: 1px solid rgb(4, 68, 68);
    border-radius: 5px;
  }

  .checkout_card
  {
    display: flex;
    flex-direction: row;
    max-width: 70%;
    border: 1px solid #044444;
    border-radius: 5px;
  }

  .final_description
  {
    font-size: 2rem;
    font-weight: 900;
    color: #022020;
  }

  .final_value
  {
    font-size: 2rem;
    color: #046814;
  }

  @media (max-width: 850px) {
    .main_checkout {
      flex-direction: column;
    }

    .payment_checkout {
        width:100%
    }

    .summary_checkout
    {
        width:100%
    }

    .checkout_button
    {
        width: 100%;
    }
  
  }