* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.body {
  max-width: 97%;
  margin: 1px auto;
}

.border {
  border: 1px solid #ff7f50;
  margin: 2px auto;
  padding: 3px 3px;
  box-sizing: border-box;
  border-radius: 10px;
}

.centerText {
  text-align: center;
}

.spinner {
  display: inline-block;
  color: #05796f;
  text-align: center;
  width: 100%;
  height: 100%;
}

.spinnerContainer {
  background-image: url("./images/logo.png");
  background-repeat: round;
  background-size: cover;
  display: flex;
  justify-content: center;
  width:8%;
  height: 20%;
  font-size: 1rem;
  margin: 5% auto;
  
}

.errorHeader
{
  color: #05796f;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 2rem;
}

.errorItem
{
  border: 1px solid #05796f;
  color:rgb(148, 32, 32);
  margin: 0.8rem;
  padding: 0.5rem;
}

.ordersConfirmed
{
  border: 1px solid #05796f;
  text-align: center;
  border-radius: 3rem;
  margin: 10%;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: #458f89;
}

.appLogo
{
  background-color: white;
}