.admin_header_navbar {
  background-color: #ff7f50;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  color: #fff;
  position: fixed;
  z-index: 500;
  top: 0;
  padding: 5px 5px;
}

.admin_header_navbrand {
  text-decoration: none;
  color: #fff;
  font-size: 22px;
  font-weight: 800;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.admin_header_navbrand:hover {
  color: #fff;
}

.admin_header_navigation {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.admin_header_dropdown_content {
  position: absolute;
  background-color: #fff;
  left: -2000px;
  top: -2000px;
  display: flex;
  flex-direction: column;
  border: 1px solid #d6410b;
  padding: 3px 20px;
  border-radius: 5px;
}

.admin_header_links {
  text-decoration: none;
  color: #ff7f50;
  font-size: 14px;
  font-family: Georgia, "Times New Roman", Times, serif;
  margin: 5px 5px;
}

.admin_header_links:hover {
  color: #d6410b;
  font-weight: 900;
}

.admin_header_dropdown {
  color: #fff;
  font-weight: 800;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 15px;
  cursor: pointer;
  border-bottom: 1px solid #d6410b;
  border-radius: 5px;
  padding: 6px 10px;
}

.admin_header_dropdown:hover {
  text-decoration: solid;

  color: #d6410b;
}

.admin_header_dropdown:hover .admin_header_dropdown_content {
  position: absolute;
  left: auto;
  top: auto;
}

.admin_header_auth_area {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  justify-items: center;
}

.admin_header_logout {
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  color: #3b1102;
  margin: auto 3px;
}

@media (max-width: 850px) {
  .admin_header_navbar {
    flex-direction: column;
  }
  .admin_header_navigation {
    flex-direction: column;
  }

  .admin_header_dropdown:hover .admin_header_dropdown_content {
    position: relative;
    left: auto;
    top: auto;
  }
}
