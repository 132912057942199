.login_block {
  border: 1px solid rgb(13, 168, 168);
  border-radius: 7px;
  margin: 8% auto;
  padding: 20px 5px;
  width: 60%;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 14px;
  text-align: center;
  color: rgb(4, 68, 68);
  box-shadow: 1px 1px 4px #a8a8a8 inset;
}

.login_statement {
  font-size: 12px;
  color: #ff7f50;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.login_button {
  all: unset;
  background-color: rgb(4, 68, 68);
  color: #fff;
  font-size: 24px;
  width: 30%;
  border-style: none;
  outline-style: none;
  border-radius: 10px;
  margin: 3px auto;
}

.login_button:hover {
  color: rgb(4, 68, 68);
  background-color: #fff;
  border: 1px solid rgb(4, 68, 68);
}

.login_button2 {
  all: unset;
  background-color: rgb(4, 68, 68);
  color: #fff;
  font-size: 25px;
  width: 30%;
  border-style: none;
  outline-style: none;
  cursor: pointer;
  margin: 3px auto;
  padding: 3px 3px;
}

.login_button2:hover {
  color: rgb(4, 68, 68);
  background-color: #fff;
  border: 1px solid rgb(4, 68, 68);
}

.login_input_box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 5px 5px;
}

.login_input {
  all: unset;
  border-style: none;
  width: 90%;
  max-height: 20%;
  margin: 1px auto;
  color: rgb(2, 36, 36);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 25px;
}

.login_input:focus {
  border-style: none;
  box-shadow: none;

}

.login_input:focus-within {
  border-style: none;
  box-shadow: none;
  
}

.login_input:focus-visible {
  border-style: none;
  box-shadow: none;
  
}

.login_label {
  font-size: 20px;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.login_anchor {
  all: unset;
  color: rgb(13, 168, 168);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 12px;
  cursor: pointer;
  margin: 10px auto;
}

.login_spinner {
  margin: 5px auto;
  text-align: center;
  color: #ff7f50;
}

.login_anchor:hover {
  font-weight: 800;
  color: rgb(4, 68, 68);
}

.customer_login_section {
  display: flex;
  justify-content: space-evenly;
  justify-items: center;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 14px;
  text-align: center;
  color: rgb(4, 68, 68);
}

.customer_login_section_login {
  margin: 8% auto;
}

.customer_login_section_signup {
  margin: 8% auto;
}

.customer_login_div_input{

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: space-around;
  flex-direction: row;
  border: 1px solid rgb(4, 68, 68);
  border-radius: 5px;
  padding: 2px 2px;
}

.signup_login_div_input{

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: space-around;
  flex-direction: row;
  border: 1px solid rgb(4, 68, 68);
  border-radius: 5px;
  padding: 2px 2px;
  margin: 0px 50px;
}

.login_div_input{

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: space-around;
  flex-direction: row;
  border: 1px solid rgb(4, 68, 68);
  border-radius: 5px;
  padding: 2px 2px;
  width:80%;
  margin: 1px auto;

}

@media (max-width: 850px) {
  .login_block {
    width: 80%;
  }

  .customer_login_section {
    flex-direction: column;
  }
}
