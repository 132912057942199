.product_items {
  display: flex;
  justify-content: center;
  justify-items: center;
  width: 100%;
  margin-left: 1px;
}

.product_items_filter {
  width: 20%;
  border: 1px solid #b4b6b5;
  padding: 8px;
}

.product_items_catalog {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-left: 15px;
}

.product_accordion_header {
  background-color: #0da8a8;
  color: #fff;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 1.5rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.product_accordion_body {
  color: #b4b6b5;
  margin-top: 5px;
  border-top: 1px solid #0da8a8;
}

.product_items_header {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  color: rgb(194, 192, 192);
}

.product_items_list {
  margin-left: 0;
  padding-left: 0;
}

.product_items_list_items {
  text-align: left;
}

.product_items_list_items * {
  margin: 1px;
  padding: 1px;
  vertical-align: middle;
}

.product_items_catalog_block {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  justify-items: left;
}

.refine_button {
  all: unset;
  background-color: #0da8a8;
  color: #fff;
  font-size: 24px;
  width: 30%;
  border-style: none;
  outline-style: none;
  padding: 4px;
  margin: 5px;
  cursor: pointer;
}

.refine_button:hover {
  color: #0da8a8;
  background-color: #fff;
  border: 1px solid #0da8a8;
}

.no_item
{
  color:#0da8a8;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 1rem;
  text-align: center;
}

.product_display_label
{
  color:#0da8a8;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 1rem;
  text-align: center;
}

.product_select_label
{
  color:#0da8a8;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 1rem;
  text-align: center;
  border: 1px solid #034242;
  border-radius: 10px;
  width: 3rem;
}

.caret {
  font-size: 1rem;
}

.caret-down {
  color: #034242;
}

.pagination
{
  margin:1rem auto;
  color: #0da8a8;
}

.page-link
{
  color: #0da8a8;
}

@media (max-width: 850px) {
  .product_items {
    flex-direction: column-reverse;
  }

  .product_items_filter {
    width: 90%;
    margin: 2px auto;
  }
}
