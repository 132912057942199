.footer_container {
  padding: 4px;
  margin: 4px ;
  border-top: 1px solid rgb(13, 168, 168);
  border-bottom: 1px solid rgb(13, 168, 168);
  color: #0da8a8;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
 
}

.footer_row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  justify-items: unset;
}

.footer_row div {
  margin: 2px 16px;
}

.footer_row_card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.footer_card {
  margin: 2px 3px;
  font-size: 1.5rem;
  color: #054e4e;
}

.footer_links{
 all: unset;

}
.footer_div {
  margin: 1px;
  padding: 1px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.footer_link {
  all:unset;
  color: #10a3a3;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 0.8rem;
}

.footer_link:hover {
  color: #ff7f50;
  cursor: pointer;
}

.footer_item_header {
  font-size: 1rem;
  color: #054e4e;
}

.footer_copy {
  font-size: 16px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: #054e4e;
}

.footer_address {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 18px;
  text-align: center;
}

.footer_social {
  margin: 2px 3px;
  font-size: 1.2rem;
  color: #054e4e;
}

.footer_social:hover {
  color: #087474;
}

.verve
{
  background-color: #065858;
  font-size: 0.8rem;
  border-radius: 5px;
  color:white
}

@media (max-width: 850px) {
  .footer_row {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer_links
  {
    text-align: center;
  }
}
