.admin_footer_main {
  text-align: center;
  color: #ff7f50;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 12px;
  bottom: 0;
  top:auto;
  box-sizing: border-box;
  position: relative;
  width: 100%;
}

.footer_copy {
  margin: 5px auto;
  color: #1c6464;
}
