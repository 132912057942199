.product_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1px 3px;
  padding: 1px 2px;
  box-sizing: content-box;
  border-radius: 6px;
}

.product_main img {
  border-radius: 10px;
  max-width: 250px;
}

.product_name {
  font-size: 1.4rem;
  font-weight: 800;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  color: #0da8a8;
  margin: 4px;
  padding: 4px;
  text-decoration: underline;

}

.product_unit_price {
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
  color: #bd3f12;
  text-decoration: line-through;
}

.product_sale_price {
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
  color: #065858;
}

.product_details_link {
  color: #0da8a8;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8rem;
  text-decoration: none;
}

.product_details_link:hover {
  color: #085757;
}

