.appbar {
  background-color: #0da8a8;
  margin-bottom: 5px;
}

.appnavbrand {
  color: #fff;
}

.appnavbrand:hover {
  color: #ff7f50;
}

.header_anchor {
  text-decoration: none;
  color: #fff;
  margin-left: 2px;
  cursor: pointer;
  font-size: 10px;
}
.header_anchor:hover {
  color: #fff;
  text-decoration: underline;
}

.header_anchor__data {
  color: #0da8a8;
}

.card_image_data
{
  color: #0da8a8;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 1.1rem;
}

.header_anchor__links {
  color: #a9a9a9;
  text-decoration: none;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.8rem;
}

.header_anchor__links:hover {
  color: #ff7f50;
}

.main {
  position: relative;
}

.cart_zone {
  display: flex;
  padding: 2px;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.cart_zone_cart_elements {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #fff;
  border-radius: 5px;
  padding: 2px;
}

.cart_zone__count {
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
}

.cart_zone__image_container {
  position: relative;
  cursor: pointer;
}

.cart_zone__image_container .tooltiptext {
  visibility: hidden;
  background-color: #043838;
  color: #fff;
  border-radius: 3rem;
  top: 60%;
  left: 50%;
  position: absolute;
  padding: 3px;
  font-size: 0.8rem;
}

.cart_zone__image_container:hover {
  border-bottom: 1px solid #fff;
}

.cart_zone__image_container:hover .tooltiptext {
  visibility: visible;
}

.cart_zone__image {
  color: #fff;
}

.cart_zone__amount {
  all: unset;
  color: #fff;
  font-size: 0.6rem;
  margin: 1px 2px;
}

@media (max-width: 850px) {
  .cart_zone {
    flex-direction: column;
    justify-content: space-evenly;
  }
}
